import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class UserManagement extends Vue {
    public headers: any[] = [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email Address', value: 'email' },
        { text: 'Last Login', value: 'lastLogin' },
        { text: 'Status', value: 'status' },
        { text: 'Role', value: 'roles', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
    ];
    public items: any[] = [];
    public search: string = '';

    public confirmUserRemoving(text: string): Promise<boolean> {
        return (this.$refs.confirm as any).open(
            'Confirm',
            text,
        );
    }

    public removeFromList(user: any): void {
        const index: number = this.items.findIndex(item => item.id === user.id);

        if (index !== -1) {
            this.items.splice(index, 1);
        }
    }
}
