



























import Vue from 'vue';
import UserApi from '@/services/api/UserApi';
import CommonRules from '@/rules/CommonRules';
import { Component } from 'vue-property-decorator';

@Component
export default class DelegateUserDialog extends Vue  {
    public dialog: boolean = false;
    public email: string = '';
    public valid: boolean = true;
    public rules = CommonRules;

    public close(): void {
        this.email = '';
        this.dialog = false;
    }

    public delegate(): void {
        UserApi.delegate(this.email).then(() => {
            this.$store.commit(
                'notificationState/setSuccessMessage',
                `User ${this.email} has been successfully added`,
            );

            this.$emit('added');

            this.close();
        }).catch(error => {
            console.error(error);
            this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
        });
    }
}
