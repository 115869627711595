import store from '@/store';
import { AxiosPromise } from 'axios';
import axios from '@/services/api/Common';

export default class UserApi {
    private static host: string = store.getters['siteConfigState/getBackend'];
    private static apiClient: any = axios.create({
        withCredentials: true,
    });
    private static config: any = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    public static getUsers(
        { page, itemsPerPage, sortBy, sortDesc }: any, search: string = '', enabled: null|boolean = null, role: string,
    ): AxiosPromise {
        let url: string = `${this.host}/api/v2/admin/connect_users/page/${page}/items/${itemsPerPage}`;

        if (sortBy) {
            const sortOrder: string = sortDesc[0] ? 'desc' : 'asc';

            url += `?sort=${sortBy}&order=${sortOrder}`;
        }

        if (search) {
            search = encodeURIComponent(search);

            url += `&search=${search}`;
        }

        if (enabled !== null) {
            url += `&enabled=${enabled}`;
        }

        if (role && role !== 'All') {
            url += `&role=${role}`;
        }

        return this.apiClient.get(url, this.config);
    }

    public static getDelegatedUsers(): AxiosPromise {
        return this.apiClient.get(`${this.host}/api/v2/users/assigned-users`, this.config);
    }

    public static deleteUser(id: number): AxiosPromise {
        return this.apiClient.delete(`${this.host}/api/v2/admin/connect_user/${id}`, this.config);
    }

    public static deleteDelegatedUser(id: number): AxiosPromise {
        return this.apiClient.delete(`${this.host}/api/v2/systems/user/${id}`, this.config);
    }

    public static delegate(email: string): AxiosPromise {
        return this.apiClient.post(`${this.host}/api/v2/systems/users`, { email }, this.config);
    }

    public static createUser(user: any): AxiosPromise {
        return this.apiClient.post(`${this.host}/api/v2/admin/connect_user`, { ...user }, this.config);
    }

    public static updateUser(id: number, data: any): AxiosPromise {
        return this.apiClient.put(`${this.host}/api/v2/admin/connect_user/${id}`, data, this.config);
    }

    public static changeUserStatus(enabled: boolean, id: number): AxiosPromise {
        return this.apiClient.put(`${this.host}/api/v2/admin/connect_user/${id}/enabled`, { enabled }, this.config);
    }
}
