











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmationDialog extends Vue {
    @Prop({ required: false, type: String, default: 'Confirm' })
    public confirmText!: string;
    public title: string = '';
    public dialog: boolean = false;
    public message: string = '';
    public reject: (reason?: any) => void = Function;
    public resolve: (value?: any) => void = Function;

    public open(title: string, message: string): Promise<void> {
        this.title = title;
        this.dialog = true;
        this.message = message;

        return new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }

    public cancel(): void {
        this.resolve(false);
        this.dialog = false;
    }

    public agree(): void {
        this.resolve(true);
        this.dialog = false;
    }
}
