export default {
    required: (value: string) => !!value || 'Required field',
    email: (value: string) => {
        const pattern = new RegExp(''
            + /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/.source
            + /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
        );

        return pattern.test(value) || 'Invalid e-mail';
    },
    phone: (value: string) => {
        if (value) {
            const pattern = new RegExp(/^[+]?(?!-)([(][0-9]{1,3}[)])?([-\s]?[0-9])+$/);
            return pattern.test(value) || 'Invalid phone number';
        } else {
            return true;
        }
    },
    confirmPassword: (confirmPassword: string, password: string) =>
        password === confirmPassword || ('Passwords don\'t match'),
    pwdMinLength: (value: string) => value.length >= 8 || 'The password must not be less than 8 characters',
};
