












































import UserApi from '@/services/api/UserApi';
import { Component } from 'vue-property-decorator';
import UserManagement from '@/components/UsersManagement/UserManagement';
import ConfirmationDialog from '@/components/common/ConfirmationDialog.vue';
import DelegateUserDialog from '@/components/UsersManagement/DelegateUserDialog.vue';

@Component({
    components: {
        DelegateUserDialog,
        ConfirmationDialog,
    },
})
export default class DelegateList extends UserManagement {
    public created(): void {
        this.headers.splice(3, 3);

        this.getUsers();
    }

    public getUsers(): void {
        this.$store.commit('notificationState/setSpinnerMessage', 'Getting users...');

        UserApi.getDelegatedUsers().then(response => {
            this.items = response.data.users;
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.$store.commit('notificationState/clearSpinnerMessage');
        });
    }

    public remove(user: any): void {
        const msg: string = 'Are you sure you want to remove this delegate user from your account?  \n' +
            'This will not remove the user\'s account overall.';

        this.confirmUserRemoving(msg).then((confirmed: boolean) => {
            if (confirmed) {
                UserApi.deleteDelegatedUser(user.id).then(() => {
                    this.$store.commit(
                        'notificationState/setSuccessMessage',
                        `User ${user.email} has been successfully removed`,
                    );

                    this.removeFromList(user);
                }).catch(error => {
                    console.error(error);
                    this.$store.commit('notificationState/setErrorMessage', error.response.data.error);
                });
            }
        });
    }
}
